<template>
  <div>
    <v-dialog v-model="showDialog" max-width="600" @keydown.esc="cancel()" persistent>
      <v-card>
        <v-card-title> Verify Loops </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container fluid pa-0>
            <v-row no-gutters class="mt-2">
              <v-col cols="12" class="black--text">
                Please place a piece of metal on the arming/closing loops to
                activate.
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-2">
              <v-col cols="12" class="black--text">
              <v-checkbox
                v-model="reversedLoop"
                :label="`Select checkbox incase loops are reversed`"
              ></v-checkbox>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-2">
              <v-col cols="6" class="text-center">
                <span class="font-weight-bold text-h6 black--text"
                  >{{ reversedLoop ? 'Closing Loop':'Arming Loop'}}</span
                >
                <v-card
                  class="mx-auto my-2"
                  :color="isArmingLoopActive ? 'green' : 'red'"
                  max-width="100"
                  height="100"
                >
                </v-card>
              </v-col>
              <v-col cols="6" class="text-center">
                <span class="font-weight-bold text-h6 black--text"
                  >{{ reversedLoop ? 'Arming Loop':'Closing Loop'}}</span
                >
                <v-card
                  class="mx-auto my-2"
                  :color="isResetLoopActive ? 'green' : 'red'"
                  max-width="100"
                  height="100"
                >
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="cancel()"
          :color="isResetLoopVerified && isArmingLoopVerified ? 'green' : 'red'">
          {{isResetLoopVerified && isArmingLoopVerified ? "Verified Successfully" : "Dismiss"}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row no-gutters>
      <v-col cols="12">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="primary elevation-0 mt-4"
              x-large
              v-bind="attrs"
              v-on="on"
              block
              @click="openDialog()"
            >
              Verify loops
              <v-icon right> all_inclusive </v-icon>
            </v-btn>
          </template>
          <span>Verify loops</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import * as API from "@/api/api";
var mqtt = require("mqtt");
export default {
  components: {},
  props: {
    isLoopReversed: {
      type: Boolean,
      default: function () {
        return false;
      }
    }
  },
  data() {
    return {
      client: null,
      showDialog: false,
      armingLoop: null,
      isArmingLoopActive: null,
      isResetLoopActive: null,
      resetLoop: null,
      isArmingLoopVerified: false,
      isResetLoopVerified: false,
      reversedLoop: false
    };
  },
  watch: {
    isLoopReversed(){
      this.reversedLoop = this.isLoopReversed;
    }
  },
  computed: {
    ...mapGetters({
      selectedZone: "pinaStore/getterSelectedZone",
      selectedLane: "pinaStore/getterSelectedLane",
    }),
  },

  methods: {
    async openDialog() {
      this.showDialog = !this.showDialog;
      this.isArmingLoopActive = false;
      this.isResetLoopActive = false;
      this.getLoopTopics();
    },
    cancel() {
      this.doUnSubscribe(this.armingLoop);
      this.doUnSubscribe(this.resetLoop);
      this.destroyConnection();
      this.showDialog = false;
      this.updateLoopVerificationStatus();
    },
    async getLoopTopics() {
      try {
        const result = this.selectedZone?.zid
          ? await API.getHardwareComponents({ zid: this.selectedZone.zid })
          : {};
        let loopSubTopic = result.data?.data
          .filter((a) => a.name == "Loops")
          .find((p) => p.lane_id == this.selectedLane.lane_id)?.sub_topic;
        this.armingLoop =
          loopSubTopic != null &&
          loopSubTopic != null &&
          loopSubTopic != undefined
            ? loopSubTopic+"/loop/0"
            : null;
        this.resetLoop =
          loopSubTopic != null &&
          loopSubTopic != null &&
          loopSubTopic != undefined
            ? loopSubTopic+"/loop/1"
            : null;
        this.createConnAndSubscribe();
      } catch (error) {
        console.log(error);
      }
    },
    createConnAndSubscribe() {
      // this.$store.commit("pinaStore/SET_LOADING_STATUS", {
      //   property: "VERIFY_LOOPS",
      //   pending: true,
      // });
      var self = this;
      let options = {
          port: process.env.VUE_APP_MQTT_OPTIONS_PORT,
          clientId: "AmitBrowser" + new Date().getTime(),
          username: process.env.VUE_APP_MQTT_OPTIONS_USERNAME,
          password: process.env.VUE_APP_MQTT_OPTIONS_PASSWORD,
          clean: process.env.VUE_APP_MQTT_OPTIONS_CLEAN,
        };
      try {
        this.client = mqtt.connect(process.env.VUE_APP_MQTT_URL, options);
      } catch (error) {
        console.log("mqtt.connect error", error);
      }
      this.client.on("connect", () => {
        console.log("connected MQTT");
        self.armingLoop != null && self.armingLoop != ""
          ? self.doSubscribe(self.armingLoop)
          : "";
        self.resetLoop != null && self.resetLoop != ""
          ? self.doSubscribe(self.resetLoop)
          : "";
      });
      this.client.on("error", (error) => {
        console.log("Connection failed", error);
      });
      this.client.on("message", (topic, message) => {
        let parsedMsg = JSON.parse(String.fromCharCode.apply(null, message));
        if (topic.charAt(topic.length - 1) == 0) {
          self.isArmingLoopActive = parsedMsg == 1 ? true : false;
          self.isArmingLoopEnabled();
        } else if (topic.charAt(topic.length - 1) == 1) {
          self.isResetLoopActive = parsedMsg == 1 ? true : false;
          self.isResetLoopEnabled();
        }
        console.log(parsedMsg);
      });
    },
    doUnSubscribe(topic) {
      if (this.client != null && topic != null && this.client != undefined) {
        this.client.unsubscribe(topic, (error) => {
          if (error) {
            console.log("Unsubscribe error", error);
          }
          console.log("UnSubscribed to topic - ", topic);
        });
      }
    },
    doSubscribe(topic) {
      if (this.client != null && topic != null && this.client != undefined) {
        this.client.subscribe(topic, (error) => {
          // console.log(res)
          if (error) {
            console.log("Subscribe to topics error", error);
            return;
          }
          console.log("Subscribe to topics", topic);
        });
      }
    },
    destroyConnection() {
      if (this.client?.connected) {
        try {
          this.client.end();
          console.log("Successfully disconnected!");
        } catch (error) {
          console.log("Disconnect failed", error.toString());
        }
      }
    },
    isArmingLoopEnabled() {
      if (this.isArmingLoopActive == null || this.isArmingLoopActive == false) {
        return;
      }
      this.isArmingLoopVerified = this.isArmingLoopActive; //Atleast once enable
    },
    isResetLoopEnabled() {
      if (this.isResetLoopActive == null || this.isResetLoopActive == false) {
        return;
      }
      this.isResetLoopVerified = this.isResetLoopActive; //Atleast once enable
    },
    updateLoopVerificationStatus() {
      let loopVefificationStatus =
        this.isResetLoopVerified && this.isArmingLoopVerified;
      this.$emit(
        "loop-verification-status",
        loopVefificationStatus,
        this.reversedLoop
      );
    },
  },
  mounted() {},
};
</script>