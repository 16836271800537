<template>
  <v-container fluid pa-0>
    <v-row no-gutters>
      <v-col cols="12">
        <v-stepper v-model="configStep" non-linear vertical>
          <v-stepper-step step="1" :complete="configStep > 1">
            Select Gate Type
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-container fluid pa-0>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-radio-group v-model="gateType" hide-details="auto">
                    <v-radio value="elka">
                      <template v-slot:label>
                        <div class="text-h5 black--text">Elka</div>
                      </template>
                    </v-radio>
                    <v-radio value="magnetic">
                      <template v-slot:label>
                        <div class="text-h5 black--text">Magnetic</div>
                      </template>
                    </v-radio>
                    <v-radio value="other">
                      <template v-slot:label>
                        <div class="text-h5 black--text">Other</div>
                      </template>
                    </v-radio>
                    <div
                      class="text-h5 black--text my-2"
                      v-show="gateType != ''"
                    >
                      <!-- <vue-ip
                        solo
                        :ip="gateIPAddress"
                        :on-change="onGateIpAddChange"
                        theme="material"
                      ></vue-ip> -->
                      <v-text-field 
                      v-model="gateIPAddress"
                      outlined
                      label="IP Address"
                      hide-details="auto"
                      maxLength="15"
                      :rules="[isValidIPAddress(gateIPAddress)]"
                      ></v-text-field>
                      <v-text-field 
                      class="pt-4"
                      v-model="gateMacAddress"
                      outlined
                      label="MAC Address (Optional)"
                      hide-details="auto"
                      :rules="[isValidMACAddress(gateMacAddress)]"
                      ></v-text-field>
                    </div>
                  </v-radio-group>
                  <!-- <div v-show="gateType == 'other'">
                    <v-text-field outlined v-model="otherGate"> </v-text-field>
                  </div> -->
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6" class="pa-1">
                  <v-btn
                    block
                    color="primary"
                    class="mb-4"
                    @click="skipStepBtnClick(1)"
                    x-large
                  >
                    Skip
                  </v-btn>
                </v-col>
                <v-col cols="6" class="pa-1">
                  <v-btn
                    block
                    color="primary"
                    class="mb-4"
                    @click="submitGateType"
                    x-large
                    :disabled="disableSubmitGateTypeBtn"
                  >
                    Continue
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-content>
          <v-stepper-step step="2" :complete="configStep > 2">
            Activate Credit Card Reader
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-container fluid pa-0>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-radio-group v-model="cc_configure">
                    <v-radio value="yes">
                      <template v-slot:label>
                        <div class="text-h5 black--text">Yes</div>
                      </template>
                    </v-radio>
                    <v-radio value="no">
                      <template v-slot:label>
                        <div class="text-h5 black--text">No</div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                  <div
                    class="text-h5 black--text my-2"
                    v-show="cc_configure == 'yes'"
                  >
                    <!-- <vue-ip
                      solo
                      :ip="ccReaderAddress"
                      :on-change="onCCReaderAddChange"
                      theme="material"
                    ></vue-ip> -->
                    <v-text-field 
                      v-model="ccReaderAddress"
                      label="IP Address"
                      hide-details="auto"
                      outlined
                      maxLength="15"
                      :rules="[isValidIPAddress(ccReaderAddress)]"
                      ></v-text-field>
                      <v-text-field 
                      class="pt-4"
                      v-model="ccMacAddress"
                      outlined
                      label="MAC Address (Optional)"
                      hide-details="auto"
                      :rules="[isValidMACAddress(ccMacAddress)]"
                      ></v-text-field>
                  </div>
                </v-col>
              </v-row>
               <v-row no-gutters>
                <v-col cols="6" class="pa-1">
                  <v-btn
                    block
                    color="primary"
                    class="mb-4"
                    @click="skipStepBtnClick(2)"
                    x-large
                    
                  >
                    Skip
                  </v-btn>
                </v-col>
                <v-col cols="6" class="pa-1">
                  <v-btn
                    block
                    color="primary"
                    class="mb-4"
                    @click="submitCCReader"
                    x-large
                    :disabled="disableSubmitCCReaderBtn"
                  >
                    Continue
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-content>
          <v-stepper-step step="3" :complete="configStep > 3">
            Activate 2D scanner
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-container fluid pa-0>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-radio-group v-model="scanner_configure">
                    <v-radio value="yes">
                      <template v-slot:label>
                        <div class="text-h5 black--text">Yes</div>
                      </template>
                    </v-radio>
                    <v-radio value="no">
                      <template v-slot:label>
                        <div class="text-h5 black--text">No</div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6" class="pa-1">
                  <v-btn
                    block
                    color="primary"
                    class="mb-4"
                    @click="skipStepBtnClick(3)"
                    x-large
                    
                  >
                    Skip
                  </v-btn>
                </v-col>
                <v-col cols="6" class="pa-1">
                  <v-btn
                    block
                    color="primary"
                    class="mb-4"
                    @click="submitScannerReader"
                    x-large
                    :disabled="disableSubmitScannerBtn"
                  >
                    Continue
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-content>
          <v-stepper-step step="4" :complete="configStep > 4">
            Activate Prox Card Reader
          </v-stepper-step>
          <v-stepper-content step="4">
            <v-container fluid pa-0>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-radio-group v-model="prox_configure">
                    <v-radio value="yes">
                      <template v-slot:label>
                        <div class="text-h5 black--text">Yes</div>
                      </template>
                    </v-radio>
                    <v-radio value="no">
                      <template v-slot:label>
                        <div class="text-h5 black--text">No</div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6" class="pa-1">
                  <v-btn
                    block
                    color="primary"
                    class="mb-4"
                    @click="skipStepBtnClick(4)"
                    x-large
                    
                  >
                    Skip
                  </v-btn>
                </v-col>
                <v-col cols="6" class="pa-1">
                  <v-btn
                    block
                    color="primary"
                    class="mb-4"
                    @click="submitProxReader"
                    x-large
                    :disabled="disableSubmitProxReaderBtn"
                  >
                    Continue
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-content>
          <v-stepper-step step="5" :complete="configStep > 5">
            Verify loops
          </v-stepper-step>
          <v-stepper-content step="5">
            <v-container>
              <v-row no-gutters>
                <v-col cols="12" class="pa-1 pb-6">
                  <verify-loops-dialog
                    :isLoopReversed = "this.isLoopReversed"
                    @loop-verification-status="isLoopsVerificationFinished"
                  >
                  </verify-loops-dialog>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6" class="pa-1">
                  <v-btn
                    block
                    color="primary"
                    class="mb-4"
                    @click="skipStepBtnClick(5)"
                    x-large
                    
                  >
                    Skip
                  </v-btn>
                </v-col>
                <v-col cols="6" class="pa-1">
                  <v-btn
                    block
                    color="primary"
                    class="mb-4"
                    @click="submitVerifyLoops"
                    x-large
                    :disabled="!loopVerficationStatus"
                  >
                    Continue
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-content>
          <v-stepper-step step="6" :complete="configStep > 6">
            Confirmation
          </v-stepper-step>
          <v-stepper-content step="6">
            <v-container fluid pa-0>
              <v-row no-gutters>
                <v-col cols="6">
                  <span>Gate Type : </span>
                </v-col>
                <v-col cols="6">
                  <span>{{ gateType == "" ? "no":"" }} </span>
                  <span v-show="gateType != ''">
                    {{ gateType.toUpperCase() }}</span
                  >
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-2" justify="end"  v-if="gateType != '' && gateIPAddress != ''">
                <v-col cols="6">
                  <span>
                    ({{ gateIPAddress }})</span
                  >
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-2" justify="end"  v-if="gateType != '' && gateMacAddress != '' && gateMacAddress != null">
                <v-col cols="6">
                  <span>
                    ({{ gateMacAddress }})</span
                  >
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-2">
                <v-col cols="6">
                  <span>CC Reader : </span>
                </v-col>
                <v-col cols="6">
                  <span>{{ cc_configure }} </span>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-2" justify="end" v-if="cc_configure == 'yes' && ccReaderAddress != ''">
                <v-col cols="6">
                  <span>
                    ({{ ccReaderAddress }})</span
                  >
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-2" justify="end" v-if="cc_configure == 'yes' && ccMacAddress != '' && ccMacAddress != null">
                <v-col cols="6">
                  <span>
                    ({{ ccMacAddress }})</span
                  >
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-2">
                <v-col cols="6">
                  <span>2D Scanner : </span>
                </v-col>
                <v-col cols="6">
                  <span>{{ scanner_configure }} </span>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-2">
                <v-col cols="6">
                  <span>Prox Scanner : </span>
                </v-col>
                <v-col cols="6">
                  <span>{{ prox_configure }} </span>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-4">
                <v-col cols="12">
                  <v-btn
                    block
                    color="primary"
                    @click="submitData"
                    :loading="loading"
                    class="mb-4"
                    x-large
                  >
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-content>
        </v-stepper>
      </v-col>
    </v-row>
    <!-- <v-row no-gutters>
      <v-col cols="12">
        <verify-loops-dialog></verify-loops-dialog>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
// import VueIp from "vue-ip";
import { mapGetters } from "vuex";
import VerifyLoopsDialog from "@/components/home/VerifyLoopsDialog.vue";
import * as API from "@/api/api";
export default {
  name: "Zoneconfig",
  // components: { VueIp, VerifyLoopsDialog },
  components: { VerifyLoopsDialog },
  data: () => ({
    configStep: 1,
    gateType: "",
    gate_configure: null,
    gateIPAddress: "",
    gateMacAddress: "",
    gateIPAddressValid: false,
    // cc_configure: false,
    ccReaderAddress: "",
    ccMacAddress: "",
    cc_configure: null,
    ccReaderAddressValid: false,
    isLoopReversed: false,
    loopVerficationStatus: false,
    scanner_configure: null,
    prox_configure: null,
  }),
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     console.log(vm);
  //   });
  // },
  computed: {
    ...mapGetters({
      selectedZone: "pinaStore/getterSelectedZone",
      selectedLane: "pinaStore/getterSelectedLane",
      laneConfigInfo: "pinaStore/getterLaneConfigInfo",
      uid: "authStore/getterUID",
    }),
    disableSubmitGateTypeBtn() {
      let disabled = this.gateType == "" || this.gateType == null;
      if (this.gateType != "") {
        disabled = disabled || this.isValidIPAddress(this.gateIPAddress) != true || this.isValidMACAddress(this.gateMacAddress) != true;
      } 
      return disabled;
    },
    disableSubmitCCReaderBtn() {
      let disabled =
        this.cc_configure == "" || this.cc_configure == null;
      if (this.cc_configure == "yes") {
        disabled = disabled || this.isValidIPAddress(this.ccReaderAddress) != true || this.isValidMACAddress(this.ccMacAddress) != true;
      }
      return disabled;
    },
    disableSubmitScannerBtn() {
      let disabled =
        this.scanner_configure == "" || this.scanner_configure == null;
      if (this.scanner_configure == "yes" || this.scanner_configure == "no") {
        disabled = false;
      }
      return disabled;
    },
    disableSubmitProxReaderBtn() {
      let disabled =
        this.prox_configure == "" || this.prox_configure == null;
      if (this.prox_configure == "yes" || this.prox_configure == "no") {
        disabled = false;
      }
      return disabled;
    },
    loading() {
      return this.$store.getters["pinaStore/getterLoadingStatus"](
        "SUBMIT_DETAILS"
      );
    },
  },
  watch: {},
  mounted() {
    let laneID = this.$router.currentRoute.params.lid;
    let data = this.laneConfigInfo.find((lane) => lane.laneID == laneID);
    if(data != "" && data != null && data != undefined){
      this.gateType = data?.gateType ? data.gateType : null;
      this.gate_configure = data?.gateConfigure ? ((Number(data.gateConfigure) == 1 ) ? "yes" : "no") : null;
      this.gateIPAddress = data?.gateIPAddress ? data.gateIPAddress.split(':')[0] : null;
      this.ccReaderAddress = data?.cardReaderIPAddress ? data.cardReaderIPAddress.split(':')[0] : null;
      this.gateMacAddress = data?.gateMacAddress ? data.gateMacAddress : null;
      this.ccMacAddress = data?.ccMacAddress ? data.ccMacAddress : null;
      this.cc_configure = data?.ccConfigure ? ((Number(data.ccConfigure) == 1 ) ? "yes" : "no") : null;
      this.isLoopReversed = data?.isLoopReversed ? data.isLoopReversed == 1 : false;
      this.scanner_configure = data?.scannerConfigure ? ((Number(data.scannerConfigure) == 1 ) ? "yes" : "no") : null;
      this.prox_configure = data?.proxConfigure ? ((Number(data.proxConfigure) == 1 ) ? "yes" : "no") : null;
    }
    this.$store.commit("pinaStore/SET_LOADING_STATUS", {
      property: "SUBMIT_DETAILS",
      pending: false,
    });
  },
  methods: {
    /**
     * Will be called on changes to IP address or port
     */

     onGateIpAddChange(ip, port, valid) {
      this.gateIPAddress = ip;
      this.gateIPAddressValid = valid;
    },
    onCCReaderAddChange(ip, port, valid) {
      this.ccReaderAddress = ip;
      this.ccReaderAddressValid = valid;
    },
    submitGateType() {
      this.configStep = 2;
    },
    submitCCReader() {
      this.configStep = 3;
    },
    submitScannerReader() {
      this.configStep = 4;
    },
    submitProxReader() {
      this.configStep = 5;
    },
    submitVerifyLoops() {
      this.configStep = 6;
    },
    skipStepBtnClick(tag){
      this.configStep = tag + 1
      if(tag == '1') {
        this.gate_configure  = "no"
      } else if(tag == '2') {
        this.cc_configure = "no"
      }  else if(tag == '3') {
        this.scanner_configure = "no"
      }  else if(tag == '4') {
        this.prox_configure = "no"
      } 
      console.log("value=" + tag)
    },
    isValidIPAddress(ip){
      let regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
      return regex.test(ip) ? true : 'Invalid IP Address'
    },
    isValidMACAddress(macAddress){
      let regex = /^[0-9a-f]{1,2}([.:-])[0-9a-f]{1,2}(?:\1[0-9a-f]{1,2}){4}$/i;
      return macAddress == "" || macAddress == null || regex.test(macAddress) ? true : 'Invalid MAC Address'
    },
    isLoopsVerificationFinished(status, loopReversed) {
      this.loopVerficationStatus = status;
      this.isLoopReversed = loopReversed;
    },
    async submitData() {
      try {
        this.$store.commit("pinaStore/SET_LOADING_STATUS", {
          property: "SUBMIT_DETAILS",
          pending: true,
        });
        let postData = {
          zid: this.selectedZone.zid,
          laneId: this.selectedLane.lane_id,
          gate_configure: this.gate_configure == "no" ? false: true,
          cc_configure: this.cc_configure == "no" ? false: true,
          scanner_configure: this.scanner_configure == "no" ? false: true,
          prox_configure: this.prox_configure == "no" ? false: true,
          gateIPAddress: this.gateIPAddress,
          cardReaderIPAddress: this.ccReaderAddress,
          isLoopReversed: this.isLoopReversed,
          gateMacAddress: this.gateMacAddress,
          ccMacAddress: this.ccMacAddress,
          gateType:this.gateType
        };
        const resultSubmitDetails = await API.submitConfigurationDetails(
          postData
        );
        if (resultSubmitDetails?.data?.status) {
          let qrCode = resultSubmitDetails.data.data.qrCode;
          this.$store.commit("pinaStore/SET_CONFIGURED_LANE_QR", qrCode);
        }
        this.$store.commit("pinaStore/SET_LOADING_STATUS", {
          property: "SUBMIT_DETAILS",
          pending: false,
        });

        this.$router.push({
          path:
            "/home/" +
            this.selectedZone.zid +
            "/" +
            this.selectedLane.lane_id +
            "/qr",
        });
      } catch (error) {
        console.log(error);
        this.$store.commit("pinaStore/SET_LOADING_STATUS", {
          property: "SUBMIT_DETAILS",
          pending: false,
        });
      }
    },
  },
};
</script>
<style>
</style>
